@media (max-width: 768px) {

    .login__right {
        display: none;
    }

    .modal-dialog {
        width: 100% !important;
        min-width: 0 !important;
    }

    .modal-content {
        width: 96% !important;
    }

    .buttons .exercise-btn {
        margin-left: 0 !important;
        margin-top: 15px !important;
    }

    .group-subject {
        flex-wrap: wrap;
        gap: 20px;
    }

    .grade .group-subject-item {
        width: 100%;
        margin-right: 0;
    }

    .grade .group-subject-item .form-select {
        width: 100%;
    }

    .grade .groups-header .header-right .form-select {
        width: 60%;
    }

    .grade .groups-header .header-right>div {
        flex-wrap: nowrap;
        white-space: nowrap;
    }

    .group-subject-schedules {
        width: 100%;
        flex-wrap: nowrap;
    }

    .groups-footer {
        flex-wrap: nowrap;
        overflow-x: auto;
        white-space: nowrap;
        scrollbar-width: 0;
    }

    .MuiPagination-ul {
        display: contents !important;
    }

    .grade-btns {
        white-space: nowrap !important;
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: 10px;
    }

    .grade-btns .button {
        margin-right: 0;
    }

    .grade-btns button:first-of-type {
        margin-right: 0;
    }

    .exercises {
        width: 100%;
        flex-wrap: wrap;
    }

    .exercises-navbar {
        overflow-x: auto;
    }

    .exercises .buttons {
        overflow-x: auto;
        width: 100%;
        margin-bottom: 20px;
    }

    .exercises .buttons button {
        margin-left: 0;
    }

    .exercises-header {
        overflow-x: auto;
    }

    .exercise-cards {
        grid-template-columns: repeat(1, 1fr);
    }

    .course-filters {
        flex-wrap: wrap;
        justify-content: left !important;
        gap: 10px;
    }

    .course-filters .cs-filters {
        width: 100% !important;
    }

    .cs_heading {
        flex-wrap: wrap;
    }

    .course-schedule .week-pagination {
        overflow-x: auto;
    }

    .groups.no-add-layer .header-right {
        width: 100%;
        gap: 5px;
        display: flex !important;
        flex-wrap: wrap;
    }

    .groups.no-add-layer .header-right .search-box {
        width: 100%;
    }

    .groups.no-add-layer .header-right .search-box input {
        width: 100%;
    }

    .groups.no-add-layer .groups-body {
        white-space: nowrap;
        overflow-x: auto;
    }

    .groups .groups-footer {
        justify-content: flex-start;
    }

    .pi-content-img {
        flex-wrap: wrap;
        white-space: nowrap;
    }

    .profile-container .profile-info .pi-form form .pi-form-wrapper {
        grid-template-columns: repeat(1, 1fr);
    }

    .edit-profile-password-form .pi-form-wrapper {
        width: 100% !important;
    }

    .password-btn-container {
        flex-wrap: wrap;
    }

    .edit-profile-password-form .pi-form-wrapper .form-row {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .password-btn-container {
        white-space: nowrap;
    }

    .save-btn {
        white-space: nowrap;
    }

    .user__img img,
    .user__img, .user-avatar {
        width: 80px!important;
        height: 80px!important;
    }

    .column-content .user-avatar{
        width: 32px!important;
        height: 32px!important;
    }

    .header-img .user-avatar {
        width: 48px!important;
        height: 48px!important;  
      }

    .user__img {
        margin-right: 18px;
    }

    .user__name {
        font-size: 18px;
    }

    .user__email {
        font-size: 12px;
    }

    .user__info {
        white-space: nowrap;
    }

    .user__top {
        padding: 20px 10px;
    }

    .exercise-cards .show.dropdown .dropdown-menu {
        padding: 5px !important;
        border: 1px solid #efefef !important;
        width: 200px !important;
    }

    .grade-student .group-subjectd-flex {
        flex-wrap: wrap;
    }

    .grade-student .group-subjectd-flex .group-subjectd-item {
        width: 100%;
    }

    .grade-student .row {
        gap: 20px;
    }

    .grade-info {
        flex-wrap: wrap;
    }

    .grade-btn {
        width: 100%;
        overflow-x: auto;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        gap: 10px;
        white-space: nowrap;
        margin-top: 10px;
    }

    .grade-student__wrapper .row .col-12 {
        width: 100% !important;
    }

    .grade-student__wrapper .row {
        gap: 20px;
    }

    .grade-student .charts .col-md-5>div {
        padding: 20px;
    }

    .grade-student .group-subject {
        flex-wrap: nowrap;
        white-space: nowrap;
        width: 100%;
        margin-right: 0;
    }

    .top__head.row {
        flex-wrap: nowrap;
    }

    .top__filter {
        margin-right: 5px;
    }

    .apexcharts-legend-series .apexcharts-legend-text {
        font-size: 14px !important;
    }

    .apexcharts-legend.apexcharts-align-center.apx-legend-position-bottom {
        align-items: flex-start!important;
        flex-direction: column;
        margin-top: 15px;
    }

    .grade-student .groups .groups-header {
        padding: 0px;
    }

    .profile-title {
        font-size: 34px;
    }

    .header-actions {
        gap: 0.5rem!important;
    }

    .header__logo {
       width: 200px;
    }

    .MuiPagination-ul {
        display: contents!important;
    }

}

@media (min-width: 768px) {
    .exercise-cards {
        grid-template-columns: repeat(2, 1fr);
    }

    .course-filters {
        justify-content: flex-end !important;
        gap: 10px;
    }

    .course-filters .cs-filters {
        width: 100% !important;
    }

    .grade-info {
        flex-wrap: wrap;
    }
    
    .grade-student .group-subject {
        flex-wrap: nowrap;
        white-space: nowrap;
        width: 100%;
        margin-right: 0;
    }

    .grade-student .header-right {
        flex-wrap: nowrap;
    }

    .MuiPagination-ul {
        display: contents!important;
    }

}

@media (min-width: 1024px) {
    .exercise-cards {
        grid-template-columns: repeat(3, 1fr);
    }

    .course-filters .cs-filters {
        width: 20% !important;
    }

}

@media (min-width: 1440px) {
    .grade-info {
        flex-wrap: nowrap;
    }

}

.course-filters {
    justify-content: flex-end;
}

.menu-logo {
	width: 100%;
	padding: 10px 0;
}

.menu-logo img {
	max-width: 150px;
	height: auto;
}

.input-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
}

.input-container {
    position: relative;
    width: 100%;
    display: flex;
    background: white;
    border: 2px solid #efefef !important;
    border-radius: 12px !important;
    background: #fcfcfc !important;
    align-items: center;

}

.input-wrapper {
    display: flex;
    align-items: center;
    /* padding: 5px; */
    width: 100%;
    background: white;
    border: 2px solid #efefef !important;
    border-radius: 12px !important;
    background: #fcfcfc !important;
}

.input-with-domain {
    flex: 1;
    border: none!important;
    border-radius: 0!important;
    width: 70%!important;
    outline: none;
    padding-right: 5px;
    overflow-x: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    scrollbar-width: thin; /* Firefox üçün */
    -ms-overflow-style: none; /* IE və Edge üçün */
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.input-wrapper input:not(input[type="radio"], input[type="checkbox"]) {
    border-radius: none!important;
}

.input-wrapper .form-control {
    border: none!important;
    border-bottom-right-radius: none!important;
    border-top-right-radius: none!important;
    border-bottom-left-radius: 12px !important;
    border-top-left-radius: 12px !important;
}

.input-with-domain::-webkit-scrollbar {
    height: 5px;
}

.email-domain {
    white-space: nowrap;
    font-size: 14px;
    color: gray;
    padding-left: 5px;
    pointer-events: none;
    background-color: #ebebeb ;
    padding: 12px;
    border-radius: 12px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    outline: solid;
    outline-width: 2px;
    outline: #ebebeb;
}


.loading-spinner {
    animation: rotate 2s linear infinite;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  