@font-face {
  font-family: 'icomoon';
  src:  url('../public/fonts/icomoon.eot?o8ktg9');
  src:  url('../public/fonts/icomoon.eot?o8ktg9#iefix') format('embedded-opentype'),
    url('../public/fonts/icomoon.ttf?o8ktg9') format('truetype'),
    url('../public/fonts/icomoon.woff?o8ktg9') format('woff'),
    url('../public/fonts/icomoon.svg?o8ktg9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"]::before, [class*=" icon-"]::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-btns:before {
  content: "\e900";
}
.icon-check:before {
  content: "\e901";
}
.icon-coders-cafe:before {
  content: "\e902";
}
.icon-coders-club:before {
  content: "\e903";
}
.icon-coders-news:before {
  content: "\e904";
}
.icon-dashboard:before {
  content: "\e905";
  color: #92929d;
}
.icon-library:before {
  content: "\e906";
}
.icon-message:before {
  content: "\e907";
  color: #fff;
}
.icon-notification:before {
  content: "\e908";
  color: #fff;
}
.icon-password_hide:before {
  content: "\e909";
}
.icon-password_show:before {
  content: "\e90a";
}
.icon-project:before {
  content: "\e90b";
  color: #92929d;
}
.icon-requests:before {
  content: "\e90c";
  color: #92929d;
}
.icon-sidebar:before {
  content: "\e90d";
}
.icon-sidebar-frame:before {
  content: "\e90e";
  color: #f5f5f7;
}
.icon-teacher:before {
  content: "\e90f";
  color: #92929d;
}
